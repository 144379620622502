.container {
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    text-align: center;
    color: #000000;
    margin-bottom: 2.8rem;
  }

  .barista {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    .img_block {
      width: 5.9rem;
      height: 5.9rem;
      border-radius: 50%;
      background: #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .info {
      margin-left: 2.5rem;
      .barista_name {
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 130%;
        color: #000000;
        margin-bottom: 0.2rem;
      }
      .barista_subname {
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 130%;
        color: #868686;
      }
    }
  }
  .tips_container {
    display: flex;
    gap: 1rem;
    overflow-y: scroll;
    padding-bottom: 2rem;
    margin-bottom: 1rem;

    .tips_item {
      background: #f8f8f8;
      border-radius: 100px;

      &.active {
        background: #ff7a00;
      }
      .tips_txt {
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #000000;
        padding: 1.5rem;
        white-space: nowrap;

        &.txt_active {
          color: #ffffff;
        }
      }
    }

    .variable_item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .variable_image {
        opacity: 0.5;
        width: 5.9rem;
        height: 5.9rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
        &.variable_active {
          opacity: 1;
        }
      }
      .variable_txt {
        opacity: 0.5;
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 2rem;
        color: #000000;
        white-space: nowrap;
        &.variable_active {
          opacity: 1;
        }
      }
    }
  }

  .input_container {
    margin-bottom: 2rem;
  }
}

.select_sum_container {
  .select_sum_top {
    .select_sum_title {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
      text-align: center;
      color: #000000;
      margin-bottom: 3rem;
    }
  }
}

// .btn_container {
//   margin-bottom: 3rem;
// }
