.container {
  padding: 0 2.5rem 2.5rem;
}
.title {
  font-size: 2.4rem;
  line-height: 3.1rem;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.btn_container {
  margin-top: 1.5rem;
}
