.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  padding: 2rem 2.5rem;
  height: 100%;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;

  &.wait_container {
    justify-content: center;

    .text {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 130%;
      color: #000;
      margin-top: 1.5rem;
      text-align: center;
    }

    .btn_container {
      margin-top: 2rem;
      width: 28rem;
    }
  }
}
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
}
.loader {
  padding: 0;
  margin: 0;
}
.title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 130%;
  color: #000;
  text-align: center;
}
.subtitle {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 130%;
  color: #000;
  margin-top: 1.5rem;
  text-align: center;
}
.app_container {
  background: #f0f0f0;
  border-radius: 1rem;
  padding: 3rem 2.5rem 2.5rem;
  margin-top: 4rem;
  width: 100%;
  box-sizing: border-box;

  .app_title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 130%;
    color: #000;
    text-align: center;
  }
  .app_subtitle {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    text-align: center;
    margin-top: 1rem;
  }
  .img_container {
    height: 13rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      transform: translateX(2rem);
    }
  }
  .btn_container {
    margin-top: 3rem;
  }
}

.back_btn {
  margin-top: 3rem;
}
