.content {
  padding: 2rem 2.5rem;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    color: #000000;
    margin-bottom: 0.6rem;
    display: flex;

    span {
      margin-left: auto;
    }
  }
  .checkbox {
    flex-direction: row-reverse;
    justify-content: left;

    &_text {
      margin-left: 0.7rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 130%;
    }
  }
}
