.container {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%) translateZ(0);
  width: 100%;
  height: 100%;
  background: #fff;
  transition: transform 0.3s ease;
  z-index: 999999;
  &.opened {
    transform: translateX(0) translateZ(0);
  }
}

.close {
  position: absolute;
  top: 2.4rem;
  left: 2.4rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 4rem;
    height: 4rem;
  }
}

.inner_container {
  padding: 8rem 3rem 3rem;
}

.menu_list {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .item {
    font-size: 2rem;
    line-height: 130%;
    color: #000;
    padding: 1.5rem 0;
    &.bold {
      font-weight: 700;
    }
    &.item_balance {
      display: flex;
      span {
        margin-left: auto;
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 130%;
        padding: 0.5rem 1rem;
        background: linear-gradient(0deg, #ff7a00, #ff7a00), #ffffff;
        border-radius: 100px;
        color: #ffffff;
      }
    }
  }
}

.user_block {
  display: flex;
  align-items: center;

  .user_pic {
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #19b2ac;
    border-radius: 50%;
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 130%;
    color: #fff;
  }
  .user_info {
    padding-left: 1.5rem;
    font-size: 1.6rem;
    line-height: 130%;
    .user_name {
      font-weight: 700;
      color: #000;
    }
    .user_phone {
      font-weight: 400;
      color: #868686;
    }
  }
  .edit_icon {
    margin-left: auto;
  }
}

.free_coffe {
  background: #ff7a00;
  border-radius: 1.5rem;
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  .free_title {
    font-weight: 700;
    font-size: 2rem;
    color: #fff;
    line-height: 130%;
  }
  .free_subtitle {
    color: #ffd4ac;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 130%;
  }
  .free_counter {
    font-weight: 700;
    font-size: 2rem;
    line-height: 130%;
    color: #fff;
  }
}
