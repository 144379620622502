.container {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  background-color: #f8f8f8;
  .overflow {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.map_container {
  width: 100%;
  height: 100%;
}
