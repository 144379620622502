@import url('./fonts/Inter/stylesheet.css');
@import url('./fonts/OpenSansRounded/stylesheet.css');

html {
  font-size: 62.5%;
}
html,
body {
  font-family: 'Open Sans Rounded';
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
.view-main {
  height: 100%;
}
a {
  text-decoration: none;
}

input {
  font-family: 'Open Sans Rounded';
  &::placeholder {
    font-family: 'Open Sans Rounded';
  }
}

body {
  .root_container {
    transition: transform 0.3s ease;
    transform: translateX(0);
  }
  &.menu_opened {
    .root_container {
      transform: translateX(100%);
    }
  }
}

#root {
  height: 100%;
  > div {
    height: 100%;
  }
}

// div {
// &::-webkit-scrollbar-track {
//   border-radius: 10px;
//   background-color: #31373e;
// }

// &::-webkit-scrollbar {
//   width: 5px;
//   background-color: #31373e;
// }

// &::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #48505a;
// }
// }

@media (max-width: 1700px) {
  html,
  body {
    font-size: 62.5%;
  }
}
@media (max-width: 1300px) {
  html,
  body {
    font-size: 43.5%;
  }
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 62.5%;
  }
}

.ReactModalPortal {
  z-index: 999999;
  position: relative;
}

.ReactModalPortal .popup-content {
  padding: 0;
  border: none;
}

.ReactModalPortal .popup-content.full-open-content {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 93rem;
}

.ReactModal__Overlay {
  opacity: 0;
  // transition: opacity 300ms ease-in-out;
}

@media (orientation: portrait) {
  .ReactModal__Overlay {
    padding: 0 0 !important;
  }
}

.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

* {
  box-sizing: initial;
  outline: none;
}
button:focus {
  outline: none;
}
button {
  cursor: pointer;
}

.circle_progress_mini {
  width: 3rem !important;
  height: 3rem;
}
.circle_progress_mini svg {
  width: 3rem !important;
  height: 3rem;
}
.circle_progress_feed_page {
  width: 8.5rem !important;
  height: 8.5rem;
}
.circle_progress_feed_page svg {
  width: 8.5rem !important;
  height: 8.5rem;
}
@media (min-width: 768px) {
  .circle_progress_feed_page {
    width: 17.5rem !important;
    height: 17.5rem;
  }
  .circle_progress_feed_page svg {
    width: 17.5rem !important;
    height: 17.5rem;
  }
}
.page_preloader {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}
.page_preloader svg {
  width: 16rem;
  height: 10rem;
}

.Toastify {
  position: relative;
  z-index: 9999999999;
}

.gallery-thumbs .swiper-slide-thumb-active .slide-content {
  border: 0.2rem solid #ff3e6c;
  border-radius: 0.5rem;
}

.swiper-button-disabled {
  opacity: 0;
}
.swiper-slide {
  position: relative;
}
.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
  .swiper-pagination-bullet {
    background: #e4e4e4;
    width: 7px;
    height: 7px;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
    }
  }
}

.Toastify__toast-container--top-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999999;
}
.Toastify__toast {
  padding: 0;
  box-shadow: none;
  background-color: rgba(2, 2, 2, 0.79);
  border-radius: 5rem;
  height: auto;
  min-height: auto;
  width: fit-content;
  .Toastify__toast-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    padding: 1rem 3rem 1rem 3rem;
    > div {
      font-weight: 500;
      font-size: 1.4rem;
      color: #ffffff;
      line-height: 1.6rem;
      margin: 0;
      white-space: nowrap;
      font-family: 'Open Sans Rounded';
    }
  }
}
