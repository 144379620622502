.content {
  padding: 0 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
}
.btn_container {
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;
  width: calc(100% - 5rem);
}

.delete_info {
  font-size: 1.5rem;
  line-height: 130%;
  color: #000;
  text-align: center;
  margin-top: 4.5rem;
}

.delete_btn {
  color: #ff6900;
  font-size: 1.5rem;
  line-height: 130%;
  text-align: center;
}
