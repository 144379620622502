.container {
  height: 100%;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  position: relative;
}
.content {
  padding: 0 2.5rem;
  padding-bottom: 5rem;
  width: 100%;
  box-sizing: border-box;
  .image {
    width: 100%;
    height: 25rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .name {
    color: #000;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 130%;
    text-align: center;
  }
  .desc {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    text-align: center;
    margin-top: 0.5rem;
  }
}
.horizontal_selectors {
  margin-top: 3rem;
}
.horiz_selector_container {
  margin-top: 1.5rem;
}
.button_container {
  position: sticky;
  position: -webkit-sticky;
  bottom: 2.5rem;
  padding: 0 2.5rem;
  width: 100%;
  box-sizing: border-box;
}
