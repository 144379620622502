.loader {
  padding: 0;
  margin: 0;
}

.btn_block {
  margin-top: 1.5rem;
}

.btn {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 2rem;
  line-height: 130%;
  font-weight: 700;
  cursor: pointer;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.orange {
    background-color: #ff7a00;
    color: #ffffff;
    border-radius: 3rem;
  }

  &.black {
    background-color: #000;
    border-radius: 1rem;
    height: 6.5rem;
    svg {
      width: 7rem;
      height: 5rem;
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
