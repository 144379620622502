.container {
  height: 100%;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  padding-bottom: 8rem;
}

.sticky_container {
  position: sticky;
  top: 2rem;
  z-index: 2;
}

.chips_container {
  display: flex;
  align-items: center;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2.5rem;
  // position: sticky;
  // top: 2rem;
  z-index: 2;
  transform: translate3d(0, 0, 0);
  &::-webkit-scrollbar {
    display: none;
  }
  .chips_item {
    background: #f6f6f6;
    border-radius: 3rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    padding: 0.6rem 1.5rem 0.7rem 1.5rem;
    margin-right: 0.5rem;
    color: #000;

    &.active {
      background: #19b2ac;
      color: #ffffff;
    }
  }
}

.products_list {
  width: 100%;
  box-sizing: border-box;
  padding: 0 2.5rem 3rem;
  display: flex;
  flex-direction: column;

  .category_title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 130%;
    color: #000;
    margin-top: 4rem;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }
  .product_item {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    position: relative;
    padding-bottom: 3.4rem;
    .product_img {
      width: 100%;
      height: 14rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .product_name {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
      color: #000;
      text-align: center;
      // height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add_btn_container {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add_btn {
      margin-top: 0.5rem;
      display: flex;
      outline: none;
      border: none;
      background-color: #ff7a00;
      border-radius: 3rem;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      font-family: 'Open Sans Rounded';
      width: fit-content;
      &:active {
        opacity: 0.7;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        padding-right: 0.5rem;
      }
      span {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #fff;
      }
    }
  }
}

.button_container {
  position: fixed;
  bottom: 2.5rem;
  padding: 0 2.5rem;
  width: 100%;
  box-sizing: border-box;
}
