.out_container {
  margin-top: 2rem;
}
.container {
  background: #f8f8f8;
  border-radius: 1.5rem;
  padding: 1.2rem 1.5rem 1rem;
  display: flex;
  justify-content: space-between;

  input {
    border: none;
    background: #f8f8f8;
    color: #000;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    flex: 1;
    width: 100%;
  }
  .right {
    flex-shrink: 0;
    color: #ff7a00;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
  }
}

.title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 130%;
  margin-bottom: 1rem;
}
.loader {
  margin: 0;
  padding: 0;
}
