.content {
  padding: 0 2.5rem 2.5rem;
}

.chat_container {
  background: #f6f6f6;
  border-radius: 2rem;
  padding: 3rem;

  .title {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 115%;
  }
  .subtitle {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 115%;
    margin-top: 0.5rem;
  }
  .btn_container {
    margin-top: 2rem;
    .btn {
      height: 4rem;
      font-size: 1.6rem;
      line-height: 130%;
    }
  }
}

.join_container {
  background: #000000;
  border-radius: 2rem;
  padding: 1.5rem 0 3rem 0;
  margin-top: 2.5rem;

  .cat_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: calc(50% - 1rem);
      height: auto;
    }
  }
  .join_txt {
    padding: 0 3rem;
    color: #fff;

    .title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 115%;
    }
    .subtitle {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 115%;
      margin-top: 0.5rem;
    }
  }
  .social_block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;

    a {
      width: 5rem;
      height: 5rem;
      margin: 0 1.5rem;
      svg {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

.policy {
  display: block;
  color: #aaaaaa;
  font-size: 1.2rem;
  line-height: 130%;
  margin-top: 2rem;
}
.mc_icon {
  margin-top: 1rem;
}
