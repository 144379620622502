.container {
  background: #000;
  min-height: 100%;
  position: relative;
}

.header {
  svg {
    path {
      fill: #fff;
    }
  }

  div {
    color: #fff !important;
  }
}

.img_container {
  width: 100%;
  height: 26rem;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
  }

  .bottom_shadow {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(180deg);
  }
}

.content {
  position: relative;
  z-index: 2;
  margin-top: 20rem;
  padding: 0 3rem 5rem;

  .title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 130%;
    color: #fff;

    span {
      color: #ff7a00;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    color: #fff;
    margin-top: 1rem;
  }

  p {
    margin: 2rem 0;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    color: #fff;

    span {
      color: #ff7a00;
    }
  }

  .black_btn {
    border: 0.2rem solid #ffffff;
    background-color: #000;
    color: #fff;
  }
}

.btn_container {
  position: sticky;
  bottom: 3rem;
  left: 3rem;
  width: calc(100% - 6rem);
  z-index: 3;
}

.result_container {
  height: 100%;
  position: relative;
}

.input_content {
  padding: 0 3rem 3rem;
}

.result_content {
  padding: 0 3rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 16rem);

  svg {
    width: 15rem;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .result_title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 130%;
    color: #000;
    margin-top: 6rem;
    text-align: center;
  }

  .result_subtitle {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    color: #000;
    margin-top: 1rem;
    text-align: center;
    padding: 0 2rem;
  }
}

.result_btn_container {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  width: calc(100% - 6rem);
}

.input_container {
  width: 100%;
}
