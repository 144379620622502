.content {
  padding: 0 2.5rem 2.5rem;
}
.txt {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 130%;
}

.coffe_container {
  width: calc(100% + 2rem);
  margin-top: 3rem;
  position: relative;
  left: -1rem;
  background: #000000;
  border-radius: 2rem;
  padding: 3rem;
  box-sizing: border-box;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 115%;
      color: #fff;
    }
    .count {
      font-weight: 700;
      font-size: 2rem;
      line-height: 130%;
      color: #fff;
    }
  }
  .subtitle {
    color: #fff;
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 130%;
  }
}

.coffe_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;

  .coffet_item_container {
    width: calc(33% - 1rem);

    .coffe_item {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      background-color: #fff;
      position: relative;
      margin-top: 2.5rem;

      &.last {
        background-color: #19b2ac;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .last_txt {
      font-weight: 700;
      font-size: 1rem;
      line-height: 120%;
      color: #fff;
      text-align: center;
    }

    .dashed_circle {
      position: absolute;
      top: 0.6rem;
      left: 0.6rem;
      svg {
        width: 6.8rem;
        height: 6.8rem;
      }
    }
    .item_img_container {
      width: 100%;
      height: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -2rem;

      .item_img {
        width: 8rem;
        height: 8rem;
        object-fit: contain;
      }
    }
    .check {
      background: #ff7a00;
      border-radius: 1.5rem;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .mumber_block {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 120%;
      color: #d6d6d6;
    }
  }
}

.rules {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 130%;
  color: #aaaaaa;
  padding: 2rem 1.5rem;
  a {
    color: #ff7a00;
  }
}
