.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 3rem;
  position: relative;

  .loader_container {
    margin: 0;
    padding: 0;
  }

  .title {
  }
  .center {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      color: #000;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 130%;
      text-align: center;
    }
    .outlet_name {
      font-size: 1.6rem;
      line-height: 130%;
      font-weight: 400;
      color: #000;
    }
    .table_name {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
      color: #000;
    }
  }
  .side_item {
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.burger {
      svg {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
  }
  .counter {
    position: absolute;
    top: -1rem;
    right: -1rem;
    background: #ff6900;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    color: #fff;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 2rem;
  }
}
