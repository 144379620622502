.app_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 768px;
  margin: 0 auto;
}
.page_content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 7rem);
}

@media screen and (min-width: 769px) {
  * {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bebebe;
    }
  }
}
