.container {
  padding: 0 2.5rem;

  .txt {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    padding-bottom: 2rem;
  }
}
