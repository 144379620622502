.basket_list {
  padding: 0 2.5rem;
}

.item {
  display: flex;
  border-bottom: 1px solid #f8f9fd;
  padding: 2.5rem 0;

  .img_container {
    width: 8rem;
    height: 9rem;
    flex-shrink: 0;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  .item_content {
    flex: 1;

    .info {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 130%;
        color: #000;
      }
      .item_volume {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 130%;
        color: #000;
      }
      .free_coffe {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 130%;
        color: #8b8b8b;
      }
      .additionals {
        margin-top: 0.5rem;
      }
      .additionals_item {
        display: flex;
        .additionals_plus {
          margin-top: 0.4em;
        }
        .list {
          padding-left: 0.5rem;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 130%;
        }
      }

      .right {
        flex-shrink: 0;
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 130%;
        color: #ff7a00;
        &.gray {
          color: #777;
        }
      }
    }
    .control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.5rem;

      .control_item {
        font-weight: 400;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.6rem;
        line-height: 130%;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
          border-right: 1px solid #f0f0f0;
        }
        &:last-child {
          border-left: 1px solid #f0f0f0;
        }
        svg {
          path {
            fill: #000;
          }
        }
      }

      .control_btn_container {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 130%;
        border-radius: 0.4rem;
        border: 1px solid #f0f0f0;
        display: flex;
        .control_txt {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}
