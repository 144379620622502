.container {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 1.5rem;
  padding: 1.2rem 1.5rem 1.2rem 2rem;
}

.name {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 130%;
  color: #000;
}
.right {
  margin-left: auto;
  padding-left: 1rem;
  display: flex;
  align-items: center;

  .distance {
    padding: 0 1.5rem 0 0.5rem;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 130%;
    color: #19b2ac;
  }
  .icon {
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trigger {
    svg {
      width: 1.2rem;
      height: 2rem;
      path {
        fill: #b0b0b0;
      }
    }
  }
}
.error_container {
  margin-top: 1rem;
}
